import React, { useState } from 'react';
import { List, PlusCircle, CheckCircle2 } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from './components/ui/alert';
const App = () => {
  const [goals, setGoals] = useState([
    { id: 1, text: "Travel to Japan", completed: false },
    { id: 2, text: "Learn to play guitar", completed: false },
    { id: 3, text: "Run a marathon", completed: true },
  ]);

  const [newGoal, setNewGoal] = useState("");

  const addGoal = () => {
    if (newGoal.trim() !== "") {
      setGoals([...goals, { id: goals.length + 1, text: newGoal, completed: false }]);
      setNewGoal("");
    }
  };

  const toggleGoal = (id) => {
    setGoals(goals.map(goal => 
      goal.id === id ? { ...goal, completed: !goal.completed } : goal
    ));
  };

  return (
    <div className="max-w-md mx-auto mt-10 p-6 bg-white rounded-lg shadow-xl">
      <h1 className="text-3xl font-bold mb-6 text-center text-blue-600">BucketGoal</h1>
      
      <div className="mb-4">
        <input
          type="text"
          value={newGoal}
          onChange={(e) => setNewGoal(e.target.value)}
          placeholder="Add a new goal..."
          className="w-full px-3 py-2 border rounded-md"
        />
        <button onClick={addGoal} className="mt-2 w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-300">
          <PlusCircle className="inline mr-2" size={18} />
          Add Goal
        </button>
      </div>

      <ul className="space-y-2">
        {goals.map(goal => (
          <li 
            key={goal.id} 
            className={`flex items-center justify-between p-2 rounded-md ${goal.completed ? 'bg-green-100' : 'bg-gray-100'}`}
          >
            <span className={goal.completed ? 'line-through text-gray-500' : ''}>{goal.text}</span>
            <button onClick={() => toggleGoal(goal.id)} className={`p-1 rounded-full ${goal.completed ? 'bg-green-500' : 'bg-gray-300'}`}>
              <CheckCircle2 size={18} className="text-white" />
            </button>
          </li>
        ))}
      </ul>

      <Alert className="mt-6">
        <List className="h-4 w-4" />
        <AlertTitle>Pro Tip</AlertTitle>
        <AlertDescription>
          Break down big goals into smaller, actionable steps for better progress tracking!
        </AlertDescription>
      </Alert>

      {/* 最后放一段英文，这是ai生成的一个bucketgoal的模板代码，真正的网页将会到来，author：creeponsky.com */}
      <div className="mt-6 text-sm text-gray-500">
        <p>
          This is a simple bucket goal tracker. More features are coming soon!
        </p>
        <p>
          Author: <a href="https://creeponsky.com" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">creeponsky.com</a>
        </p>
      </div>
    </div>
  );
};

export default App;